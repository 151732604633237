import React from "react"
import PropTypes from "prop-types"

const FullWidthSection = ({ children, blue, style, lightGrey, anchorName}) => (
  <section style={style} className={`full-width-section ${blue ? "blue" : ""} ${lightGrey ? "off-white-background" : ""}`}>
    {anchorName ? (<>
      <a id={anchorName} />
    {children}
    </>) : children}

  </section>
)

FullWidthSection.propTypes = {
  blue: PropTypes.bool,
  lightGrey: PropTypes.bool,
  anchorName: PropTypes.string,
}

export default FullWidthSection
