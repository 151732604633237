import React from "react"
import { Link } from "gatsby"
import { Container, Row } from "react-grid-system"
import Layout from "./layout"
import SEO from "./seo"
import FullWidthSection from "../components/layout/fullWidthSection"
import InnerContentContainer from "../components/layout/innerContentContainer"
import Column from "../components/layout/column"

import NotFoundImage from "../assets/404-not-found.svg"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <FullWidthSection>
        <InnerContentContainer>
          <Container>
            <Row>
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1 style={{ marginBottom: "3.5rem" }}>Page Not Found</h1>
                <img
                  style={{ maxHeight: 250, marginBottom: "3rem" }}
                  className="img-responsive"
                  src={NotFoundImage}
                  alt="Page Not Found"
                />
                <button className="btn">
                  <Link to={`/`}>Back To Home</Link>
                </button>
              </Column>
            </Row>
          </Container>
        </InnerContentContainer>
      </FullWidthSection>
    </Layout>
  )
}

export default NotFoundPage
